const theme = {
  colors: {
    primary: '#000000',
    primaryLight: '#000000',
    primaryDark: '#3d0435',
    bg: '#fff',
    text: '#021212',
    textInvert: '#fff',
    greyLight: '#B4B9C1',
    greyMedium: '#414141',
    greyDark: '#2F2F2F',
  },
  fontFamily: {
    body: `'Inter', 'Cera Pro', 'Open Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    heading: `'Morion', 'Bree Serif', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
  maxWidth: '1800px',
  maxWidthText: '1000px',
  breakpoint: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
};

export default theme;
