module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Luzia Johow Filmmaker & Editor Portfolio', // Navigation and Site Title
  siteTitleAlt: 'Luzia Johow', // Alternative Site title for SEO
  siteUrl: 'https://portfolio-bella.netlify.com', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteDescription: 'Filmmaker and editor, living and working in Vienna, Austria',
  author: 'V.F.X. Johow', // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '', // Twitter Username
  ogSiteName: '', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: '#000000',
  backgroundColor: '#ffffff',
};
